.historyList {
    margin: 1rem 0;
}
  
.historyList thead tr:nth-child(1) th {
    background: white;
    position: sticky;
    top: 0;
}
  
.historyList .table-responsive {
    max-height: 400px;
}