@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round|Material+Icons+Two+Tone|Material+Icons+Sharp");
@import url("https://fonts.googleapis.com/css?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

@import "bootstrap";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  background-color: #f3f6f9;
  font-family: Inter, sans-serif;
  margin-top: 58px;
}

.Main {
  display: flex;
  flex-direction: column;
  min-height: 94vh;
  .Footer {
    flex: 0 0 auto;
  }
  .MainContent,
  .Login,
  .ForgotPassword {
    flex: 1 0 auto;
  }
  .MainHeader {
    flex: 0 0 auto;
  }
}

.material-icons,
.material-icons-outlined,
.material-symbols-outlined {
  vertical-align: middle;
  margin-top: -2px;

  &.md-12 {
    font-size: 12px;
  }

  &.md-14 {
    font-size: 12px;
  }

  &.md-16 {
    font-size: 16px;
  }

  &.md-18 {
    font-size: 18px;
  }

  &.md-20 {
    font-size: 20px;
  }

  &.md-24 {
    font-size: 24px;
  }

  &.md-32 {
    font-size: 32px;
  }

  &.md-36 {
    font-size: 36px;
  }

  &.md-48 {
    font-size: 48px;
  }

  &.md-64 {
    font-size: 64px;
  }

  &.md-72 {
    font-size: 72px;
  }

  &.md-84 {
    font-size: 84px;
  }

  &.md-96 {
    font-size: 96px;
  }

  &.md-120 {
    font-size: 120px;
  }
}
.sticky-header th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

.dropdown {
  .dropdown-toggle::after {
    font-family: "Material Icons";
    font-size: 24px;
    color: inherit;
    vertical-align: middle;
    line-height: 1;
    margin: -2px 0 0 0;
    content: "\e5cf";
    border: none;
  }
  .dropdown-toggle.bg-opacity-10:hover {
    @extend .bg-opacity-25;
  }
}

.navbar-nav {
  .nav-item.dropdown.bg-opacity-10:hover {
    @extend .bg-opacity-25;
  }
}

.btn:focus {
  box-shadow: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
