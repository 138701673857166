.ContentDetails {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5;

  img,
  figure,
  .ratio {
    margin: 15px 0;
    @extend .img-fluid !optional;
  }

  h1 {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h4 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h5 {
    font-size: 14px;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h6 {
    font-size: 12px;
    letter-spacing: normal;
    line-height: 1.3;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.5;
    margin-bottom: 6px;
  }
  a {
    font-size: inherit;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.5;
  }
  .btn {
    min-width: 180px;
  }
  .whatsnew {
    width: 20rem;
    height: auto;
    padding: 10px;
  }
  .divider {
    height: 0.5px;
  }
}
