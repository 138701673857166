.state-col {
  width: 200px;
}
.sticky-header {
  position: sticky !important;
  top: 120px !important;
}
.card-header {
  position: sticky !important;
  top: 33px !important;
}
@media (min-width: 1220px) and (max-width: 2560px) {
  .desktop-table {
    visibility: visible;
  }
  .mobile-table {
    display: none;
  }
}
@media (min-width: 300px) and (max-width: 1220px) {
  .desktop-table {
    display: none;
  }
  .mobile-table {
    visibility: visible;
  }
}
.table-fs {
  font-size: 14px;
}
