.emails-container {
  max-height: 150px;
  overflow-y: auto;
}
.icon-btn {
  width: 2rem;
  height: 2rem;
}
.icon-add {
  font-size: 1.1rem;
}

.icon-del {
  font-size: 1.1rem;
}

.nomination-detail {
  height: 230px;
  overflow-y: scroll;
  margin-bottom: 10px;
}
