.not-found {
    font-size: 5em;
}

.not-found-container {
    display: flex;
    flex-direction: column;
    height: 90vh;
    margin: 42px 0px 0px 0px;
    align-items: center;
}   