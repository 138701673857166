.WhatsNew {
  .DropDown {
    width: 380px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
  }

  .DropDownItem {
    padding: 0 !important;
  }

  .divider {
    height: 0.5px;
    padding: 0;
    margin: 0;
  }

  .gray {
    color: #828282;
  }

  .w-max {
    width: fit-content;
  }

  .unreadNotifications {
    background-color: #dee2e680;
    padding: 10px 10px 2px 10px;
  }

  .readNotifications {
    background-color: white;
    padding: 10px 10px 2px 10px;
  }

  .borderBottomColor {
    border-bottom: 1px solid #c7c7c7;
  }
}
